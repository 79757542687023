
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'admins-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedRole = ref<number>(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const adminSchema = Yup.object().shape({
        name: Yup.string()
          .required(() => translate('FULL_NAME_IS_REQUIRED_FIELD'))
          .label('Name'),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
        password: Yup.string()
          .required(() => translate('PASSWORD_IS_REQUIRED_FIELD'))
          .min(4, () => translate('PASSWORD_MIN_4'))
          .max(20, () => translate('PASSWORD_MAX_20'))
          .matches(/^(?=.*[a-z])/, translate('PASSWORD_AT_LEAST_ONE_LOWER'))
          .matches(/^(?=.*[A-Z])/, translate('PASSWORD_AT_LEAST_ONE_UPPER'))
          .matches(/^(?=.*\d)/, translate('PASSWORD_AT_LEAST_ONE_NUMBER'))
          .matches(
            /^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?].*$/,
            translate('PASSWORD_AT_LEAST_ONE_SPECIAL')
          )
          .label('Password'),
        verifyPassword: Yup.string()
          .required(() => translate('VERIFY_PASSWORD_IS_REQUIRED_FIELD'))
          .oneOf([Yup.ref('password'), null], () =>
            translate('PASSWORDS_MUST_MATCH')
          )
          .label('Verify Password'),
        roleId: Yup.number()
          .notOneOf([0], () => translate('ROLE_IS_REQUIRED_FIELD'))
          .required(() => translate('ROLE_IS_REQUIRED_FIELD'))
          .label('Role'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        delete values.verifyPassword;

        await store.dispatch(Actions.CREATE_ADMIN_ACCOUNT, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_ADMIN'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'admins-listing' });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('adminUsersCreating'), [
          translate('members'),
        ]);
      });

      await store.dispatch(Actions.GET_ROLES, { limit: 50 });

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        roles: computed(() => store.getters.rolesList),
        onSubmitCreate,
        submitButton,
        translate,
        adminSchema,
        goBack,
        selectedRole,
        can,
      };
    },
  });
